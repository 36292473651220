import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {restconfig} from '../config/config';
import {switchMap,} from 'rxjs/operators';
import {of, iif, throwError, Observable} from 'rxjs';
import {AppState, getAuthToken} from '../store';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private _token

  constructor(private _http: HttpClient, private _store: Store<AppState>) {

    this._store.select(getAuthToken).subscribe(t=>this._token=t)
  }

  setOrderStatus(event: string, orderId: string): Observable<any>{

    const timeStamp = (new Date().toISOString()).substring(0,19)

    const body = new HttpParams()
      .set('nounce', '12345')
      .set('event', event)
      .set('orderId', orderId)
      .set('companion', '0')
      .set('utctime', timeStamp)

    return this._http.post(restconfig.url +  restconfig.setorderstatus, body, this.httpOptions()).pipe(
      switchMap(data=>{
        return iif(()=>data['status']!==200,
          throwError(data),
          of('ok')
        )
      })
    )

  }

  setMissionStatus(event: string, missionId: string): Observable<any>{

    const timeStamp = (new Date().toISOString()).substring(0,19)

    const body = new HttpParams()
      .set('nounce', '12345')
      .set('event', event)
      .set('missionId', missionId)
      .set('utctime', timeStamp)

    return this._http.post(restconfig.url +  restconfig.setmissionstatus, body, this.httpOptions()).pipe(
      switchMap(data=>{
        return iif(()=>data['status']!==200,
          throwError(data),
          of('ok')
        )
      })
    )

  }


  httpOptions(){
    return { headers: new HttpHeaders(
      {'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Bearer ' + this._token}
              )
            }
  }

}


