import { Component, OnInit, OnDestroy } from '@angular/core';
import {ApiKey} from '../../config/config';
import {AppState, getAppCurrentStatus, getAppState, getEndPoint, getMapDirectionLink, getPosition} from '../../store';
import {Store} from '@ngrx/store'
import {Subscription} from 'rxjs';
import {getTriggerState} from '../../store';
import {filter, switchMap, tap} from 'rxjs/operators';
import {ResetCaltulationTriger, SetOpenMapInNewTab} from '../../store/georeducer/geoactions';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  mapEmbedUrl: string
  apiKey = ApiKey
  lat: number;
  lng: number;

  endlat: number;
  endlng: number;
  openMapInNewTab: boolean;

  private _subscription: Subscription = new Subscription()

  constructor(private _store: Store<AppState>) { }

  ngOnInit() {

   let sub$ = this._store.select(getPosition).subscribe(
      position=>{
        this.lat = position.lat
        this.lng = position.lng
      }
    )
    this._subscription.add(sub$)

    this.mapEmbedUrl  = `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.lat},${this.lng}`

    /*
    sub$ = this._store.select(getTriggerState)
      .pipe(
        filter(tr=>tr),
        tap(()=>{
          sub$ =  this._store.select(getEndPoint)
            .subscribe(
              position=>{
                this.endlat = position.lat
                this.endlng = position.lng
             console.log(`https://www.google.com/maps/embed/v1/directions
                        key=${this.apiKey}
                        &origin=${this.lat},${this.lng}
                        &destination=${this.endlat},${this.endlng}`)
               /* this.mapEmbedUrl  = `https://www.google.com/maps/embed/v1/directions
                        key=${this.apiKey}
                        &origin=${this.lat},${this.lng}
                        &destination=${this.endlat},${this.endlng}`

                this._store.dispatch(new ResetCaltulationTriger())
              }
            )
          this._subscription.add(sub$)
        })
      ).subscribe()

    this._subscription.add(sub$)
    */

    sub$= this._store.select(getTriggerState).pipe(
      filter(tr=>tr)
      ).subscribe(
      ts=>{
        console.log('infinite loop cc')
        this._store.dispatch(new ResetCaltulationTriger())

        sub$ = this._store.select(getEndPoint)
          .subscribe(
            point=>{
              this.endlat = point.lat
              this.endlng = point.lng
              this.openMapInNewTab = point.openMapInNewTab
            }
          )
        this._subscription.add(sub$)

        this.mapEmbedUrl  = `https://www.google.com/maps/embed/v1/directions?key=${this.apiKey}&origin=${this.lat},${this.lng}&destination=${this.endlat},${this.endlng}`

        if(this.endlng!==0.0 && this.endlat!==0.0 && this.openMapInNewTab)
        {
          const linkAnd = `maps.google.com/maps?ll=${this.lat},${this.lng}&z=6&t=m&hl=en-US&gl=US&mapclient=embed&saddr=${this.lat},${this.lng}&daddr=${this.endlat},${this.endlng}&dirflg=d`;
          //const linkMac = `maps.google.com/maps/dir/?api=1&?saddr=${this.lat},${this.lng}&daddr=${this.endlat},${this.endlng}&directionsmode=driving`
          this.mapSelector(linkAnd);
        }
        if (!this.openMapInNewTab) {
          this._store.dispatch(new SetOpenMapInNewTab(true));
        }
     }
     )
    this._subscription.add(sub$)
    console.log('mapa', this.mapEmbedUrl)
 }

  mapSelector(link: string){
    let platform = navigator.platform.toLowerCase()
    console.log('Navigator platform', platform)
    if /* if we're on iOS, open in Apple Maps */
    (  platform.includes("iphone")  ||
       platform.includes("ipad")  ||
       platform.includes("ipod") ||
       platform.includes('mac')){
      console.log('Mac open map', link)
      window.open(`https://`+link, '_blank')
    }
    else{ /* else use Google */
      console.log('PC open map', link)
     window.open(`https://`+link,'_blank')
    }
   }

   ngOnDestroy(){
    this._subscription.unsubscribe()
 }

}
