export const restconfig = {
  url: `https://dispo-dev2.tixi24.ch/rest/private/v2/`,
  getpools: `getPools`,
  login: `register`,
  missions: `getMissions`,
  setorderstatus: 'setOrderStatus',
  setmissionstatus: 'setMissionStatus',
  uuid: '746e5199-71b3-4cea-ac4a-35688aa65356'
}

/*
export const servers = {
  'Tixi-Linth': 'https://dispo.tixi-linth.ch/rest/private/v2/',
  'Tixi-Linth_Test': `https://dispo-test.tixi-linth.ch/rest/private/v2/`,
  'Tixi24': `https://dispo.tixi24.ch/rest/private/v2/`,
  'dev1': `https://dispo-dev1.tixi24.ch/rest/private/v2/`,
  'dev2': `https://dispo-dev2.tixi24.ch/rest/private/v2/`,
  'dev3': `https://dispo-dev3.tixi24.ch/rest/private/v2/`,
  'dev4': `https://dispo-dev4.tixi24.ch/rest/private/v2/`
}

*/

export const servers = {
  'Tixi-Aargau': 'https://dispo.tixi-aargau.ch/rest/private/v2/',
  'Tixi-Aargau TEST': 'https://dispo-test.tixi-aargau.ch/rest/private/v2/',
}


export const ApiKey =  'AIzaSyBCyR92eHYPx8iywjWYhMUVLcyJxbJe33k'


